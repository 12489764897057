import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useStoreActions } from 'easy-peasy'
import { PropTypes } from 'prop-types'

import { NOT_SET_PLACEHOLDER } from '../../../../helpers/Constants'
import { Translation } from '../../../../helpers/Translation'

import Select from '../../../../components/Select'
import Typography from '../../../../components/Typography'
import Checkbox from '../../../../components/Checkbox'
import Input from '../../../../components/Input'

import './index.scss'

const GeneralSettings = ({ newOrderTemplate }) => {
  const updateTemplateValue = useStoreActions((actions) => actions.order.updateTemplateValue)
  const getEditTemplate = useStoreActions((actions) => actions.order.getEditTemplate)

  const { id: editTemplateId } = useParams()

  const [generalSettingsData, setGeneralSettingsData] = useState({
    trigger: '',
    pricing: '',
    processing_days: '',
    processing_times: '',
    one_order_per_folder: false,
    max_pic_count: '',
    max_waiting_seconds: '',
  })
  const [deliverySettingsData, setDeliverySettingsData] = useState({
    client_approval_required: false,
    partial_delivery: false,
    client_input_comments: false,
    delivery_via_link_list: false,
  })
  const [optionalSettingsData, setOptionalSettingsData] = useState({
    skip_previously_processed_filenames: false,
    is_prioritised: false,
    flag_files_input: '',
    flag_files_output: '',
  })
  const [spSettingsData, setSpSettingsData] = useState({
    sp_preset: '',
    sp_price: '',
    sp_turnaround: '',
    sp_assignment_overwrite: false,
  })
  const [invoiceSettingsData, setInvoiceSettingsData] = useState({
    cost_center: '',
    cost_center_existing: '',
  })

  useEffect(() => {
    if (newOrderTemplate) {
      setGeneralSettingsData({
        trigger: newOrderTemplate.trigger.value,
        pricing: newOrderTemplate.pricing.value,
        processing_days: newOrderTemplate.processing_days.value,
        processing_times: newOrderTemplate.processing_times.value,
        one_order_per_folder: newOrderTemplate.one_order_per_folder,
        max_pic_count: newOrderTemplate.max_pic_count,
        max_waiting_seconds: newOrderTemplate.max_waiting_seconds,
      })
      setDeliverySettingsData({
        client_approval_required: newOrderTemplate.client_approval_required,
        partial_delivery: newOrderTemplate.partial_delivery,
        delivery_via_link_list: newOrderTemplate.delivery_via_link_list,
      })
      setOptionalSettingsData({
        skip_previously_processed_filenames: newOrderTemplate.skip_previously_processed_filenames,
        is_prioritised: newOrderTemplate.is_prioritised,
        client_input_comments: newOrderTemplate.client_input_comments,
        flag_files_input: newOrderTemplate.flag_files_input,
        flag_files_output: newOrderTemplate.flag_files_output,
      })
      setSpSettingsData({
        sp_preset: newOrderTemplate.sp_preset.value,
        sp_price: newOrderTemplate.sp_price,
        sp_turnaround: newOrderTemplate.sp_turnaround,
        sp_assignment_overwrite: newOrderTemplate.sp_assignment_overwrite,
      })
      setInvoiceSettingsData({
        cost_center: newOrderTemplate.cost_center,
        cost_center_existing: newOrderTemplate.cost_center_existing.value,
      })
    }
  }, [newOrderTemplate])

  const handleUpdateCostCenter = async (value) => {
    await updateTemplateValue(
      { template_id: newOrderTemplate?.template_id, id: 'cost_center', value },
    )
    getEditTemplate(editTemplateId)
  }

  return (
    <div className="general-settings">
      <div className="settings-section">
        <Typography variant="subtitle" font="semibold" label={Translation.general_settings} />
        <div className="settings-section-columns">
          {['trigger', 'pricing', 'processing_days', 'processing_times'].map((item, index) => (
            /* eslint-disable react/no-array-index-key */
            <div key={index} className="settings-section-column">
              <div className="column-title">
                <Typography variant="xs" label={Translation[item]} />
              </div>
              <Select
                isEmptyOption={false}
                values={newOrderTemplate[item]?.options}
                value={generalSettingsData[item] || ''}
                onChange={(e) => {
                  setGeneralSettingsData((prev) => ({ ...prev, [item]: e.target.value }))
                  updateTemplateValue({ template_id: newOrderTemplate?.template_id, id: item, value: e.target.value })
                }}
              />
            </div>
          ))}
        </div>
        <div className="settings-section-bottom">
          <div className="section-bottom-left">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
              <Checkbox
                checked={generalSettingsData.one_order_per_folder}
                onChange={(e) => {
                  setGeneralSettingsData((prev) => ({ ...prev, one_order_per_folder: e.target.checked }))
                  updateTemplateValue(
                    { template_id: newOrderTemplate?.template_id, id: 'one_order_per_folder', value: e.target.checked },
                  )
                }}
              />
              {Translation.one_order_per_folder}
            </label>
            <Typography variant="xs" label="(order name = folder name if not combined)" />
          </div>
          <div className="section-bottom-right">
            <Typography variant="xs" label="(Optional) if multiple folders are ready for pickup" />
            <div className="right-inputs">
              <div className="count-input">
                <Typography variant="xs" label="Combine to:" />
                <Typography variant="xs" label="Max pic count" />
                <Input
                  type="number"
                  value={generalSettingsData.max_pic_count || ''}
                  onChange={(e) => {
                    setGeneralSettingsData((prev) => ({ ...prev, max_pic_count: e.target.value }))
                  }}
                  onBlur={(e) => {
                    updateTemplateValue(
                      { template_id: newOrderTemplate?.template_id, id: 'max_pic_count', value: e.target.value },
                    )
                  }}
                />
              </div>
              <div className="count-input">
                <Typography variant="xs" label="Combine to:" />
                <Typography variant="xs" label="Max waiting seconds" />
                <Input
                  type="number"
                  value={generalSettingsData.max_waiting_seconds || ''}
                  onChange={(e) => {
                    setGeneralSettingsData((prev) => ({ ...prev, max_waiting_seconds: e.target.value }))
                  }}
                  onBlur={(e) => {
                    updateTemplateValue(
                      { template_id: newOrderTemplate?.template_id, id: 'max_waiting_seconds', value: e.target.value },
                    )
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="settings-section">
        <Typography variant="subtitle" font="semibold" label={Translation.delivery_settings} />
        <div className="settings-section-inputs">
          {
            [
              'client_approval_required',
              'partial_delivery',
              'delivery_via_link_list',
            ].map((item, index) => (
              // eslint-disable-next-line jsx-a11y/label-has-associated-control
              <label key={index}>
                <Checkbox
                  checked={deliverySettingsData[item]}
                  onChange={(e) => {
                    setDeliverySettingsData((prev) => ({ ...prev, [item]: e.target.checked }))
                    updateTemplateValue(
                      { template_id: newOrderTemplate?.template_id, id: item, value: e.target.checked },
                    )
                  }}
                />
                {Translation[item]}
              </label>
            ))
          }
        </div>
      </div>
      <div className="settings-section">
        <Typography variant="subtitle" font="semibold" label={Translation.optional_settings} />
        <div className="settings-section-inputs">
          {
            [
              'skip_previously_processed_filenames',
              'is_prioritised',
              'client_input_comments',
            ].map((item, index) => (
              // eslint-disable-next-line jsx-a11y/label-has-associated-control
              <label key={index}>
                <Checkbox
                  checked={optionalSettingsData[item]}
                  onChange={(e) => {
                    setOptionalSettingsData((prev) => ({ ...prev, [item]: e.target.checked }))
                    updateTemplateValue(
                      { template_id: newOrderTemplate?.template_id, id: item, value: e.target.checked },
                    )
                  }}
                />
                {Translation[item]}
              </label>
            ))
          }
          {
            ['flag_files_input', 'flag_files_output'].map((item, index) => (
              <div key={index} className="count-input">
                <Typography variant="xs" label={`${Translation.flag_files}: ${Translation[item]}`} />
                <Input
                  placeholder={NOT_SET_PLACEHOLDER}
                  value={optionalSettingsData[item] || ''}
                  width="auto"
                  onChange={(e) => {
                    setOptionalSettingsData((prev) => ({ ...prev, [item]: e.target.value }))
                  }}
                  onBlur={(e) => {
                    updateTemplateValue(
                      { template_id: newOrderTemplate?.template_id, id: item, value: e.target.value },
                    )
                  }}
                />
              </div>
            ))
          }
        </div>
      </div>
      <div className="settings-section two-column">
        <div className="section-half">
          <Typography variant="subtitle" font="semibold" label={Translation.sp_settings} />
          <div className="section-half-content">
            <div className="settings-section-column">
              <div className="column-title">
                <Typography variant="xs" label="SP Preset" />
              </div>
              <div className="column-select">
                <Select
                  placeholder={Translation.please_select_an_option}
                  values={newOrderTemplate?.sp_preset?.options}
                  value={spSettingsData.sp_preset || ''}
                  onChange={(e) => {
                    setSpSettingsData((prev) => ({ ...prev, sp_preset: e.target.value }))
                    updateTemplateValue({ template_id: newOrderTemplate?.template_id, id: 'sp_preset', value: e.target.value })
                  }}
                />
              </div>
            </div>
            <div className="settings-section-column">
              <div className="column-title">
                <Typography variant="xs" label={Translation.sp_price} />
              </div>
              <div className="column-input">
                <Input
                  type="number"
                  value={spSettingsData.sp_price || ''}
                  width="auto"
                  onChange={(e) => {
                    setSpSettingsData((prev) => ({ ...prev, sp_price: e.target.value }))
                  }}
                  onBlur={(e) => {
                    updateTemplateValue(
                      { template_id: newOrderTemplate?.template_id, id: 'sp_price', value: e.target.value },
                    )
                  }}
                  endAdornment={Translation.euro_sign}
                />
              </div>
            </div>
            <div className="settings-section-column">
              <div className="column-title">
                <Typography variant="xs" label={Translation.sp_turnaround} />
              </div>
              <div className="column-input">
                <Input
                  type="number"
                  value={spSettingsData.sp_turnaround || ''}
                  width="auto"
                  onChange={(e) => {
                    setSpSettingsData((prev) => ({ ...prev, sp_turnaround: e.target.value }))
                  }}
                  onBlur={(e) => {
                    updateTemplateValue(
                      { template_id: newOrderTemplate?.template_id, id: 'sp_turnaround', value: e.target.value },
                    )
                  }}
                  endAdornment={Translation.hour_short}
                />
              </div>
            </div>
            <div className="settings-section-column">
              <div className="column-title">
                {' '}
              </div>
              <div className="column-input">
                <div className="settings-section-inputs">
                  <div className="m-top-6">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label>
                      <Checkbox
                        checked={spSettingsData.sp_assignment_overwrite}
                        onChange={(e) => {
                          setSpSettingsData((prev) => ({ ...prev, sp_assignment_overwrite: e.target.checked }))
                          updateTemplateValue(
                            {
                              template_id: newOrderTemplate?.template_id,
                              id: 'sp_assignment_overwrite',
                              value: e.target.checked,
                            },
                          )
                        }}
                      />
                      {Translation.sp_assigment_overwrite}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-half">
          <Typography variant="subtitle" font="semibold" label={Translation.invoice_settings} />
          <div className="section-half-content">
            <div className="settings-section-column">
              <div className="column-title">
                <Typography variant="xs" label={Translation.cost_center_separate_invoice} />
              </div>
              <div className="column-input">
                <Input
                  placeholder={NOT_SET_PLACEHOLDER}
                  value={invoiceSettingsData.cost_center || ''}
                  width="auto"
                  onChange={(e) => {
                    setInvoiceSettingsData((prev) => ({ ...prev, cost_center: e.target.value }))
                  }}
                  onBlur={(e) => handleUpdateCostCenter(e.target.value).then(() => { })}
                />
              </div>
            </div>
            {Object.entries(newOrderTemplate?.cost_center_existing?.options).length > 0 && (
              <div className="settings-section-column" style={{ width: '50%' }}>
                <div className="column-title">
                  <Typography variant="xs" label={Translation.select_existing} />
                </div>
                <div className="column-select" style={{ width: 'auto' }}>
                  <Select
                    placeholder={Translation.please_select_an_option}
                    values={newOrderTemplate?.cost_center_existing?.options || ''}
                    value={invoiceSettingsData.cost_center_existing}
                    onChange={(e) => {
                      setInvoiceSettingsData((prev) => ({ ...prev, cost_center_existing: e.target.value }))
                      handleUpdateCostCenter(newOrderTemplate?.cost_center_existing?.options[e.target.value]).then(() => { })
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralSettings

GeneralSettings.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  newOrderTemplate: PropTypes.object,
}

GeneralSettings.defaultProps = {
  newOrderTemplate: {},
}
