import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { SketchPicker } from 'react-color'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'

import { ORDER_PAGE, TEMPLATE_PAGE, WORKFLOW_PAGE } from '../../../../helpers/Constants'
import { isAdminRole } from '../../../../helpers/Utils'
import { Translation } from '../../../../helpers/Translation'

import Select from '../../../../components/Select'
import Input from '../../../../components/Input'
import Checkbox from '../../../../components/Checkbox'
import Typography from '../../../../components/Typography'

import ColumnSwitch from '../../Common/ColumnSwitch'
import SectionTop from '../../Common/SectionTop'

import { ReactComponent as ScissorsSvg } from '../../../../svg/scissors.svg'

import './index.scss'

const Clipping = ({
  handlePropertyId,
  orderFormatActions,
  disableAll,
  isTestOrder,
  templateType,
}) => {
  const user = useStoreState((state) => state.user.user)
  const updateLocaleFormatActions = useStoreActions((actions) => actions.order.updateLocaleFormatActions)

  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [color, setColor] = useState()

  useEffect(() => {
    setColor(`#${orderFormatActions.clipping_background_othercolour.value ?? 'fff'}`)
  }, [orderFormatActions])

  const bgColorValues = {
    white: Translation.white,
    transparent: Translation.transparent,
    other: Translation.other_colour,
    ...(templateType === WORKFLOW_PAGE && { variable: Translation.variable_colours }),
  }

  const withPathValues = {
    1: `1 ${Translation.path}`,
    2: `2 ${Translation.paths}`,
    3: `3 ${Translation.paths}`,
    4: `4 ${Translation.paths}`,
    5: `5 ${Translation.paths}`,
    6: `6 ${Translation.paths}`,
    7: `7 ${Translation.paths}`,
    8: `8 ${Translation.paths}`,
    9: `9 ${Translation.paths}`,
    10: `10+ ${Translation.paths}`,
  }

  const withMaskValues = {
    'alpha channel mask': Translation.alpha_channel_mask,
    'clipping/layer mask': Translation.clipping_layer_mask,
  }

  const layersValues = {
    2: `2 ${Translation.layers}`,
    3: `3 ${Translation.layers}`,
    4: `4 ${Translation.layers}`,
    5: `5 ${Translation.layers}`,
    6: `6 ${Translation.layers}`,
    7: `7 ${Translation.layers}`,
    8: `8 ${Translation.layers}`,
    9: `9 ${Translation.layers}`,
    10: `10+ ${Translation.layers}`,
  }

  const styles = {
    color: {
      width: '36px',
      height: '14px',
      borderRadius: '2px',
      backgroundColor: orderFormatActions.clipping_background_othercolour.value,
    },
    swatch: {
      padding: '5px',
      background: color,
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0, 0, 0, .1)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    popover: {
      position: 'absolute',
      zIndex: '2',
    },
    cover: {
      position: 'fixed',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    },
  }

  const handleClick = () => {
    if (disableAll) return
    setDisplayColorPicker(!displayColorPicker)
  }

  const handleClose = () => {
    setDisplayColorPicker(false)
  }

  return (
    <section className={`new-order-section section-clipping ${orderFormatActions?.clipping?.value ? 'active' : ''}`}>
      <Accordion expanded={!!orderFormatActions?.clipping?.value} className="accordion">
        <AccordionSummary className="accordion-summary">
          <SectionTop
            showContent={!!orderFormatActions?.clipping?.value}
            onChange={(id, value) => handlePropertyId(id, value)}
            id="clipping"
            icon={<ScissorsSvg />}
            defaultChecked={orderFormatActions?.clipping?.value !== null}
            labelText={orderFormatActions?.clipping?.label}
            modalText={Translation.clipping_modal_text}
            badgeText={orderFormatActions?.clipping?.price}
            disabled={disableAll}
          />
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <div className="section-content">
            <div className="section-content-columns">
              <div className="section-content-column">
                <ColumnSwitch
                  id="clipping_background"
                  labelText={orderFormatActions?.clipping_background?.label}
                  modalText={Translation.clipping_bg_color_modal}
                  badgeText={orderFormatActions?.clipping_background?.price}
                  defaultChecked={orderFormatActions?.clipping_background?.value !== null}
                  onChange={(id, value) => handlePropertyId(id, value)}
                  disabled={disableAll}
                />
                <div
                  className="column-content"
                  style={{ display: orderFormatActions?.clipping_background?.value ? 'block' : 'none' }}
                >
                  <div className="column-select">
                    <Select
                      isEmptyOption={false}
                      values={bgColorValues}
                      value={orderFormatActions?.clipping_background_colour?.value || ''}
                      onChange={(e) => handlePropertyId('clipping_background_colour', e.target.value)}
                      itemClass="new-order-select-item"
                      disabled={disableAll}
                    />
                  </div>
                  {
                    orderFormatActions?.clipping_background_colour?.value === 'other' && (
                      <div style={{ position: 'relative' }}>
                        <div
                          style={disableAll ? { ...styles.swatch, cursor: 'initial' } : { ...styles.swatch }}
                          onClick={handleClick}
                        >
                          <div style={styles.color} />
                        </div>
                        {displayColorPicker ? (
                          <div style={styles.popover}>
                            <div style={styles.cover} onClick={handleClose} />
                            <SketchPicker
                              color={color}
                              onChange={(value) => setColor(value.hex)}
                              onChangeComplete={(value) => handlePropertyId('clipping_background_othercolour', `#${value.hex}`)}
                            />
                          </div>
                        ) : null}

                      </div>
                    )
                  }
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="clipping-check-label">
                    <Checkbox
                      checked={orderFormatActions?.clipping_background_semitransparent?.value !== null}
                      onChange={(e) => handlePropertyId('clipping_background_semitransparent', e.target.checked)}
                      disabled={disableAll}
                    />
                    <Typography
                      variant="body1"
                      theme="light"
                      label={(
                        <>
                          {Translation.edit_semitransparencies}
                          <span className="price-badge">
                            {orderFormatActions?.clipping_background_semitransparent?.price}
                          </span>
                        </>
                      )}
                    />
                  </label>
                </div>
              </div>
              <div className="section-content-column">
                <ColumnSwitch
                  id="clipping_path"
                  labelText={orderFormatActions?.clipping_path?.label}
                  modalText={Translation.clipping_with_path_modal}
                  badgeText={orderFormatActions?.clipping_path?.price}
                  defaultChecked={orderFormatActions?.clipping_path?.value !== null}
                  onChange={(id, value) => handlePropertyId(id, value)}
                  disabled={disableAll || orderFormatActions?.clipping_path?.is_disabled}
                  tooltipText={isTestOrder ? Translation.test_order_disabled_tooltip_text : ''}
                />
                <div className="column-content" style={{ display: orderFormatActions.clipping_path?.value ? 'block' : 'none' }}>
                  <div className="column-select">
                    <Select
                      isEmptyOption
                      placeholder={Translation.please_select_an_option}
                      values={withPathValues}
                      value={orderFormatActions?.clipping_path_count?.value || ''}
                      onChange={(e) => handlePropertyId('clipping_path_count', e.target.value)}
                      itemClass="new-order-select-item"
                      disabled={disableAll}
                    />
                  </div>
                  <div className="column-inputs">
                    {
                      Array.from({ length: orderFormatActions?.clipping_path_count?.value }, (item, index) => (
                        <div key={index} className="column-input">
                          <Input
                            type="text"
                            placeholder={`${index + 1}. ${Translation.path_name_optional}`}
                            value={orderFormatActions[`clipping_path${index + 1}_name`]?.value || ''}
                            onBlur={(e) => handlePropertyId(`clipping_path${index + 1}_name`, e.target.value)}
                            onChange={(e) => {
                              updateLocaleFormatActions({
                                [`clipping_path${index + 1}_name`]:
                                  { ...orderFormatActions[`clipping_path${index + 1}_name`], value: e.target.value },
                              })
                            }}
                            disabled={disableAll}
                          />
                        </div>
                      ))
                    }
                    {isAdminRole(user?.role_after_login) && (
                      <>
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className="clipping-check-label">
                          <Checkbox
                            checked={orderFormatActions?.clipping_path_exact_sorting?.value !== null}
                            onChange={(e) => handlePropertyId('clipping_path_exact_sorting', e.target.checked)}
                            disabled={disableAll}
                          />
                          <Typography
                            variant="body1"
                            theme="light"
                            label={Translation.check_sorting}
                          />
                        </label>
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className="clipping-check-label">
                          <Checkbox
                            checked={orderFormatActions?.clipping_path_not_outside_canvas?.value !== null}
                            onChange={(e) => handlePropertyId('clipping_path_not_outside_canvas', e.target.checked)}
                            disabled={disableAll}
                          />
                          <Typography
                            variant="body1"
                            theme="light"
                            label={Translation.path_not_outside_of_canvas}
                          />
                        </label>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="section-content-column">
                <ColumnSwitch
                  id="clipping_mask"
                  labelText={orderFormatActions?.clipping_mask?.label}
                  modalText={Translation.clipping_with_mask_modal}
                  badgeText={orderFormatActions?.clipping_mask?.price}
                  defaultChecked={orderFormatActions?.clipping_mask?.value !== null}
                  onChange={(id, value) => handlePropertyId(id, value)}
                  disabled={disableAll}
                />
                <div className="column-content" style={{ display: orderFormatActions.clipping_mask?.value ? 'block' : 'none' }}>
                  <div className="column-select">
                    <Select
                      isEmptyOption={false}
                      values={withMaskValues}
                      value={orderFormatActions?.clipping_mask_type?.value || Object.keys(withMaskValues)[0]}
                      onChange={(e) => handlePropertyId('clipping_mask_type', e.target.value)}
                      itemClass="new-order-select-item"
                      disabled={disableAll}
                    />
                  </div>
                </div>
              </div>
              <div className="section-content-column">
                <ColumnSwitch
                  id="clipping_separate"
                  labelText={orderFormatActions?.clipping_separate?.label}
                  modalText={Translation.clipping_layers_modal}
                  badgeText={orderFormatActions?.clipping_separate?.price}
                  defaultChecked={orderFormatActions?.clipping_separate?.value !== null}
                  onChange={(id, value) => handlePropertyId(id, value)}
                  orderFormatActions={orderFormatActions}
                  disabled={disableAll || orderFormatActions?.clipping_separate?.is_disabled}
                  tooltipText={isTestOrder ? Translation.test_order_disabled_tooltip_text : ''}
                />
                <div
                  className="column-content"
                  style={{ display: orderFormatActions?.clipping_separate?.value ? 'block' : 'none' }}
                >
                  <div className="column-select">
                    <Select
                      isEmptyOption
                      placeholder={Translation.please_select_an_option}
                      values={layersValues}
                      value={orderFormatActions?.clipping_separate_count?.value || ''}
                      onChange={(e) => handlePropertyId('clipping_separate_count', e.target.value)}
                      itemClass="new-order-select-item"
                      disabled={disableAll}
                    />
                  </div>
                  <div className="column-inputs">
                    {
                      Array.from({ length: orderFormatActions?.clipping_separate_count?.value }, (item, index) => (
                        <div key={index} className="column-input">
                          <Input
                            type="text"
                            placeholder={`${index + 1}. ${Translation.layer_name_optional}`}
                            value={orderFormatActions[`clipping_separate${index + 1}_name`]?.value || ''}
                            onBlur={(e) => handlePropertyId(`clipping_separate${index + 1}_name`, e.target.value)}
                            onChange={(e) => {
                              updateLocaleFormatActions({
                                [`clipping_separate${index + 1}_name`]:
                                  { ...orderFormatActions[`clipping_separate${index + 1}_name`], value: e.target.value },
                              })
                            }}
                            disabled={disableAll}
                          />
                        </div>
                      ))
                    }
                    {isAdminRole(user?.role_after_login) && (
                      // eslint-disable-next-line jsx-a11y/label-has-associated-control
                      <label className="clipping-check-label">
                        <Checkbox
                          checked={orderFormatActions?.clipping_separate_exact_sorting?.value !== null}
                          onChange={(e) => handlePropertyId('clipping_separate_exact_sorting', e.target.checked)}
                          disabled={disableAll}
                        />
                        <Typography
                          variant="body1"
                          theme="light"
                          label={Translation.check_sorting}
                        />
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </section>
  )
}

Clipping.propTypes = {
  handlePropertyId: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  orderFormatActions: PropTypes.objectOf(PropTypes.any),
  disableAll: PropTypes.bool,
  isTestOrder: PropTypes.bool,
  templateType: PropTypes.oneOf(['', TEMPLATE_PAGE, WORKFLOW_PAGE, ORDER_PAGE]),
}

Clipping.defaultProps = {
  orderFormatActions: {},
  disableAll: false,
  isTestOrder: false,
  templateType: '',
}

export default Clipping
