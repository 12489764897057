import React, { useEffect } from 'react'
import { useStoreActions } from 'easy-peasy'
import { useNavigate } from 'react-router-dom'

import { Translation } from '../../helpers/Translation'
import { DEFAULT_PAGE, PX_TO_REM } from '../../helpers/Constants'

import Typography from '../../components/Typography'
import Button from '../../components/Button'

import OrderSuccessfulAnimation from '../../animation/order_successful_animation.json'

import { ReactComponent as SuccessfulCheckmarkSvg } from '../../svg/successful_checkmark.svg'

import './index.scss'

const splashDefaultOptions = {
  loop: false,
  autoplay: true,
  animationData: OrderSuccessfulAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const Loader = () => <SuccessfulCheckmarkSvg className="loader-icon" />

const PaymentSuccess = () => {
  const Lottie = React.lazy(() => import('lottie-react'))
  const navigate = useNavigate()

  const getUnpaidDetailsCheckout = useStoreActions((state) => state.invoice.getUnpaidDetailsCheckout)
  const setPaymentInProcess = useStoreActions((state) => state.invoice.setPaymentInProcess)

  useEffect(() => {
    getUnpaidDetailsCheckout()
    setPaymentInProcess(false)
  }, [])

  return (
    <div className="payment-success-page">
      <div className="page-icon-container">
        <React.Suspense fallback={<Loader />}>
          <Lottie {...splashDefaultOptions} className="lottie-animation" />
        </React.Suspense>
      </div>
      <div className="title-container">
        <Typography
          variant="xl"
          theme="dark"
          font="semibold"
          label={Translation.payment_successful}
        />
      </div>
      <div className="subtitle-container">
        <Typography variant="h6" theme="dark" lineHeight={PX_TO_REM['25']} label={Translation.thank_you_for_payment} />
      </div>
      <div className="button-container">
        <Button
          size="xs"
          type="fit-content"
          label={Translation.go_to_dashboard}
          onClick={() => navigate(DEFAULT_PAGE)}
        />
      </div>
    </div>
  )
}

export default PaymentSuccess
